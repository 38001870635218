import React, { useEffect, useState } from 'react';
import { css } from '@chakra-ui/styled-system';
import { useTheme } from '@chakra-ui/react';
import { Global } from '@emotion/react';

const FullPageGradientBackground = ({ topColor, bottomColor, direction = 'to-b' }: { topColor: string; bottomColor: string; direction?: string }) => {
	const [isEnabled, setIsEnabled] = useState(false);
	const theme = useTheme();
	const s = css({
		bgGradient: `linear(${direction}, ${topColor}, ${bottomColor})`,
		bgAttachment: 'fixed',
		bgSize: 'auto 100%',
		_before: {
			content: '\'\'',
			position: 'fixed',
			top: 0,
			left: 0,
			width: '100%',
			height: '50vh',
			backgroundColor: topColor,
			zIndex: -1
		},
		_after: {
			content: '\'\'',
			position: 'fixed',
			bottom: 0,
			left: 0,
			width: '100%',
			height: '50vh',
			backgroundColor: bottomColor,
			zIndex: -1
		}
	});

	// Prevents flash of colors
	useEffect(() => {
		setIsEnabled(true);
	}, []);

	if (isEnabled) {
		return (
			<Global
				styles={{
					'html, body': s(theme),
					html: {
						minHeight: '100%'
					}
				}}
			/>
		);
	}

	return null;
};

export default FullPageGradientBackground;
