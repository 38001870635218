import React, { useEffect, useState, useCallback } from 'react';
import { useRouter } from 'next/router';
import {
  Button,
  Box,
  VStack,
  Divider,
  Link,
  Text,
  Container,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Input,
  Textarea,
} from '@chakra-ui/react';
import useAuth from 'src/lib/hooks/use-auth';
import FullPageGradientBackground from 'src/components/common/full-page-gradient-background';
import { CustomNextPage } from 'src/lib/types';
import Image from 'next/image';
import Logo from '../../public/images/logo.svg';
import { useCookies } from 'react-cookie';

const LoginPage: CustomNextPage = () => {
  const router = useRouter();
  const [cookies, setCookie, removeCookie] = useCookies(['error-count']);
  const [isBeingHandled, setIsBeingHandled] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [errorMessageText, setErrorMessageText] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const { authHref, handleAuthCallback, token, consumeRedirect, handleLogout } = useAuth();

  const handleAuthCallbackWrapper = useCallback(async (code: string, state: string) => {
    try {
      setIsLoading(true);
      let response = await handleAuthCallback(code, state);
      if (response) {
        setIsLoading(false);
        if (!response?.userIsActive) {
          setHasError(true);
          setErrorMessageText("Oops, your account subscription was expired.");
          router.push('https://tlmethod.memberful.com/account')
        }
      } else {
        removeCookie('error-count');
        setHasError(true);
        setErrorMessageText("Network Error");
        handleLogout();
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error)
      setHasError(true);
      setErrorMessageText("There was an unexpected error. Please try again later.");
    }
  }, [handleAuthCallback]);

  // Exchange auth code when Memberful redirects back with code
  const { query: { code, state, redirect_to }, isReady } = router;

  useEffect(() => {
    if (!isReady) {
      return;
    }
    if (code && state && !token && !isBeingHandled && !hasError) {
      setIsBeingHandled(true);
      void handleAuthCallbackWrapper(code as string, state as string)
        .finally(() => {
          setIsBeingHandled(false);
        });
    } else if (token && !isBeingHandled) {
      void consumeRedirect();
      setIsLoading(false);
    } else if (code && redirect_to && redirect_to.includes("bounce")) {
      // handleLogout();
    } else {
      setIsLoading(false);
    }
  }, [isReady, code, state, hasError, handleAuthCallbackWrapper, token, consumeRedirect, isBeingHandled]);

  return (
    <Box
      w="full"
      minH="100vh"
      pt={12}
      pb={12}
    >
      <FullPageGradientBackground topColor="purple.300" bottomColor="blue.500" direction="to-br" />

      <Box
        position="absolute"
        w="90%"
        h="50%"
        left="5%"
        top="5%"
        opacity="0.1"
      >
        <Image src={Logo} layout="fill" />
      </Box>

      <Container position="relative">
        <VStack spacing={10}>
          <Image src={Logo} />

          {
            hasError && (
              <Alert status="error">
                <AlertIcon />
                <AlertTitle>Error:</AlertTitle>
                <AlertDescription>
                  {errorMessageText}
                </AlertDescription>
              </Alert>
            )
          }
          <a href={authHref}>
            <Button isLoading={isLoading} isDisabled={isLoading}>
              Log In
            </Button>
          </a>

          <Divider opacity={1} />

          <Text color="white" maxW="80vw" className='login-question-text-container-style-new'>
            <span>Need access to TL Method?</span>

            {' '}
            {/* TODO: link to Memberful signup */}
            <Link
              target='_blank'
              href='https://www.taralaferrara.com/tl-method-payment-plans'
              colorScheme="white"
              variant="2">
              Choose a membership
            </Link>
          </Text>
          <Button onClick={() => setIsOpen(true)}>
            Contact Us
          </Button>
        </VStack>
      </Container>


      <Modal isCentered closeOnOverlayClick={false} isOpen={isOpen} onClose={() => { setIsOpen(false); }} size='md'>
        <ModalOverlay />
        <ModalContent>
          <form action="mailto:tara@taralaferrara.com?subject=Support request from TL-Method" method="post" encType="text/plain">
            <ModalHeader>Get in touch</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              <FormControl>
                <FormLabel htmlFor="name">Name</FormLabel>
                <Input type="text" id="name" name="name" />
              </FormControl>
              <FormControl mt={4}>
                <FormLabel htmlFor="mail">E-mail</FormLabel>
                <Input type="email" id="mail" name="mail" />
              </FormControl>
              <FormControl mt={4}>
                <FormLabel htmlFor="comment">Content</FormLabel>
                <Textarea id="content" name="content" size="50" p={2} rows={6} />
              </FormControl>
            </ModalBody>
            <ModalFooter>
              <Button type="submit" variant="primary" mr={3}>
                Send
              </Button>
              <Button colorScheme="gray" onClick={() => { setIsOpen(false); }}>
                Close
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>

    </Box>
  );
};

LoginPage.title = 'Login';

export default LoginPage;
